
<template>
    <div @click="changeCustomize(ind)"
         @mouseover="addHoverPorperty(ind)"
        @mouseleave="removeHoverPorperty(ind)"
        v-html="data">
    </div>
</template>
<script>
export default{

props:{
    data:String

}
}
</script>
